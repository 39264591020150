import type { ButtonProps } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import type { FC } from 'react';

import { ArrowRight } from '~/assets/icons/arrow-right';

interface LandingCtaButtonProps extends ButtonProps {
  inverted?: boolean;
  label?: string;
  onClick: VoidFunction;
}

export const LandingCtaButton: FC<LandingCtaButtonProps> = ({
  inverted,
  label,
  onClick,
  ...rest
}) => (
  <Button
    onClick={onClick}
    alignItems="center"
    backgroundColor={inverted ? 'white' : 'black'}
    color={inverted ? 'black' : 'white'}
    borderRadius={100}
    height="74px"
    padding="24px 40px"
    _hover={{
      backgroundColor: inverted ? 'rgba(255,255,255,.85)' : 'rgba(0,0,0,.85)',
      color: inverted ? 'black' : 'white',
    }}
    {...rest}
  >
    {label ?? 'Create Artwork'}{' '}
    <ArrowRight
      cursor="pointer"
      ml="12px"
      fill={inverted ? 'black' : 'white'}
    />
  </Button>
);
